import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Components
import { H4, Paragraph } from "../typography"

const Wrapper = styled(Link)`
  text-decoration: none;
  position: relative;
`

const Caption = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0%;
  padding: 1rem 2.5rem;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  background: rgb(51, 51, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`

const StyledImage = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
  }
`

const AthleteCard = ({ input }) => {
  return (
    <Wrapper to={`/athlete/${input.slug.current}`}>
      {input.image.asset.url && (
        <StyledImage
          fluid={input.image.asset.fluid}
          alt={input.image.asset.title}
          objectFit="cover"
        />
      )}
      <Caption>
        <H4 color={props => props.theme.colors.white}>{input.name}</H4>
        {input.yearsActive && (
          <Paragraph emphasis color={props => props.theme.colors.white}>
            {input.yearsActive.startYear} – {input.yearsActive.endYear}
          </Paragraph>
        )}
      </Caption>
    </Wrapper>
  )
}

export default AthleteCard
