import React from "react"
import { graphql } from "gatsby"

// Components
import { Layout } from "../components/layout"
import { PaginationButton, PaginationWrapper } from "../components/pagination"
import { AthletesWrapper, AthleteCard } from "../components/lineup"

const LineupTemplate = ({ data, pageContext }) => {
  const doc = data.allSanityAthlete.edges
  if (!doc) return null

  const lineupList = doc.map(athlete => {
    return <AthleteCard input={athlete.node} />
  })

  // Lineup Page Navigation
  const { currentPage, numLineupPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numLineupPages
  const prevPage = currentPage - 1 === 1 ? `/` : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <AthletesWrapper>{lineupList}</AthletesWrapper>
      <PaginationWrapper>
        {!isFirst && (
          <PaginationButton to={`/lineup${prevPage}`} rel="prev">
            ← Previous Page
          </PaginationButton>
        )}
        {!isLast && (
          <PaginationButton to={`/lineup${nextPage}`} rel="next">
            Next Page →
          </PaginationButton>
        )}
      </PaginationWrapper>
    </Layout>
  )
}

export const query = graphql`
  query LineupQuery($skip: Int!, $limit: Int!) {
    allSanityAthlete(
      sort: { fields: yearsActive___startYear, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }

          yearsActive {
            startYear
            endYear
          }
          image {
            asset {
              url
              title
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default LineupTemplate
