import styled from "styled-components"

const AthletesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }
  grid-gap: 16px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

export default AthletesWrapper
